import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/fenjian',
    name: 'fenjian',
    component: () => import('../views/fenjian.vue')
  },
  {
    path: '/ps',
    name: 'ps',
    component: () => import('../views/ps.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order.vue')
  },
]
const router = new VueRouter({
  routes
})

export default router